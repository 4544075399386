import React, { useState, useEffect, useRef} from 'react';
import axios from 'axios'
const UpdateKnowledge = () => {
  const [copied, setCopied] = useState(false);
  const [success, setSuccess] = useState('')
  const [date,  setDate] = useState('');

  useEffect(()=>{
    if(success != ''){
      setTimeout(()=>setSuccess(''), 3000);
    }
  } , [success])


  const updateDate = ()=>{
    axios.get(process.env.REACT_APP_BACKEND+'/last-change').then(response=>{
      const currentDate = new Date(response.data.last_date)


      const year = currentDate.getFullYear();
      const month = currentDate.getMonth() + 1; // Note: Months are zero-indexed
      const date = currentDate.getDate();

      // Extract time components
      const hours = currentDate.getHours();
      const minutes = currentDate.getMinutes();
      const seconds = currentDate.getSeconds();
      setDate(`${year}-${month}-${date} ${hours}:${minutes}:${seconds}`)
    }).catch(error=>{
      console.log("ERRROR while getting last change " , error)
    })
  }
  const ref = useRef(0);
  useEffect(()=>{
    if(ref.current == 0){
      ref.current += 1;
      updateDate();
    }
  } ,  [])
  const copyToClipboard = () => {
    const url = process.env.REACT_APP_KNOWLEDGE_LINK;
    navigator.clipboard.writeText(url)
      .then(() => setCopied(true))
      .catch((error) => console.error('Error copying to clipboard:', error));
  };

  const updateDocument = () => {
    axios.post(process.env.REACT_APP_BACKEND+'/update').then(response=>{
      

      updateDate();
    }).catch(error=>{
        console.log("errr while updating document " , error);
    })
  };

   return(     <div className="container mx-auto p-6">

            <div className="flex items-center justify-between bg-white shadow rounded-lg p-6 mb-6">
                <div className="flex items-center">
                    
                    <div className="ml-4">
                        <p className="text-lg font-bold text-gray-800" style={{fontWeight: '800'}}>Custom Knowledge Doc:</p>
                        <a href={`${process.env.REACT_APP_KNOWLEDGE_LINK}`} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:text-blue-600 font-semibold">{`(${process.env.REACT_APP_KNOWLEDGE_LINK})`}</a>
                    </div>
                </div>
                <div className="flex-column">
                  <div>
                      <button onClick={copyToClipboard} className="btn btn-warning btn-sm"  style={{height: '25px', marginLeft : '5px'}}>Copy Link</button>
                      <button onClick={updateDocument} className="btn btn-accent btn-sm" style={{height: '25px', marginLeft: '5px'}}>Update Doc</button>
                  </div>
                  <div className="flex">
                  <p style={{fontStyle: 'itlaic'}}>Last update:</p> <p style={{fontWeight: '800'}}>{date}</p>
                  </div>
                </div>
               
            </div>
        </div>
    );
};


export default UpdateKnowledge;
