
import React, { useState, useEffect } from 'react';
import axios from 'axios'
function useGetUserStat() {
    const [info, setInfo] = useState([]);
    useEffect(()=>{
        axios.get(`${process.env.REACT_APP_BACKEND}/user-stat`).then(response=>{
            console.log(" RESPONSE IS " , response.data)
            setInfo(response.data.result)
        }).catch(error => console.log("ERROR WHILE DOING user-stat " , error))
    }, [])
    return info;
}

export default useGetUserStat;
