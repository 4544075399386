import React, { useState, useEffect } from 'react';

const UploadFile = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [error, setError] = useState('');

  const handleFileChange = (event) => {
    setError('');
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = () => {
    if (!selectedFile) {
      setError('Please select a file to upload.');
      return;
    }

    const formData = new FormData();
    formData.append('file', selectedFile);

    fetch(process.env.REACT_APP_BACKEND+'/upload', {
      method: 'POST',
      body: formData
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Failed to upload file.');
      }
      return response.json();
    })
    .then(data => {
      console.log('File uploaded successfully:', data);
      setSelectedFile(null);
    })
    .catch(error => {
      console.error('Error uploading file:', error);
      setError('Failed to upload file. Please try again.');
    });
  };

  return (
    <div>
      <input type="file" onChange={handleFileChange} />
      <button className="btn info" onClick={handleUpload}>Upload</button>
      {error && <div style={{ color: 'red' }}>{error}</div>}
    </div>
  );
};


export default UploadFile;
