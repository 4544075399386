import React, { useState, useEffect } from 'react';

function TextWithLinks({ text }) {
  // Regular expression to match URLs
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  
  // Split the text into parts: text and links
  const parts = text.split(urlRegex);

  // Render each part as either text or a link
  return (
      <div>
          {parts.map((part, index) => {
              if (index % 2 === 0) {
                  // Even index parts are plain text
                  return <span key={index}>{part}</span>;
              } else {
                  // Odd index parts are links
                  return (
                      <a key={index} href={part} style={{color: 'blueviolet'}} target="_blank" rel="noopener noreferrer">
                          {part}
                      </a>
                  );
              }
          })}
      </div>
  );
}
//
const RawTable = ({questions}) => {
  
  function convertUrlsToLinks(text) {
    // Regular expression to match URLs
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    
    // Replace URLs with anchor tags
    return text.replace(urlRegex, '<a href="$1" target="_blank">$1</a>');
}

  return (
    
    <div className="p-10">
      <h1 className="text-3xl font-bold mb-6" style={{fontWeight : '800'}}> Questions Table</h1>
      <table className="table w-full">
        <thead>
          <tr>
            <th>ID</th>
            <th>WhatsApp</th>
            <th>Phone</th>
            <th>Question</th>
            <th>Response</th>
            <th>Mark</th>
            <th>Timestamp</th>
          </tr>
        </thead>
        <tbody>
          {questions.map((question) => (
            <tr key={question.id}>
              <td>{question.id}</td>
              <td>{question.chat_id}</td>
              <td>{question.phone_number}</td>
              <td><TextWithLinks text={question.question} /></td>
              <td><TextWithLinks text={question.response} /></td>
              <td className={`bg-${question.mark == 'answered' ? 'success' : (question.mark == 'trash' ? 'error' : 'info')}`}>{question.mark}</td>
              <td>{question.timestamp}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default RawTable;
