import logo from './logo.svg';
import './App.css';
import RawTable from './Table.tsx'
import UserTable from './UserTable.tsx'
import React, { useState, useEffect, useRef } from 'react';
import useGetUserStat from './hooks/useGetUserStat.js'
import QuestionsTable from './QuestionsTable.tsx'
import useGetQuestions from './hooks/useGetQuestions.js';
import UploadFile from './UploadFile.tsx'
import UpdateKnowledge from './UpdateKnowledge.tsx'
import axios from 'axios'
import bcrypt from 'bcryptjs';

function App() {

  const [display , setDisplay] = useState(0)

  const questions = useGetQuestions();
  const info = useGetUserStat();

  // Fetch questions from server

  
  const [logged , setLogged] = useState(false);
  const [loading , setLoading] = useState(false);
  const handleUserInput = () => {
    const userInput = window.prompt('Enter something:');
    return userInput;

  };

  const handleAuth = ()=>{
    
    if(localStorage.getItem("pwd") === undefined || localStorage.getItem("pwd") === null){
      const password = handleUserInput(); // Replace with the actual password
      const saltRounds = 10; // Number of salt rounds
  
      try {
        const hash = bcrypt.hashSync(password, saltRounds);
        console.log('Hashed password:', hash);
        localStorage.setItem("pwd", hash);
      } catch (error) {
        console.error('Error hashing password:', error);
      }
     
    }
    const pwd = localStorage.getItem("pwd")
    setLoading(true);
    axios.post(process.env.REACT_APP_BACKEND+'/auth', {pwd : pwd}).then(response=>{
      setLoading(false);
      setLogged(true);
    }).catch(error => {
      setLoading(false);
      alert("Wrong password");
      localStorage.removeItem("pwd");
      handleAuth();
    })
  }
  const ref = useRef(0);
  useEffect(()=>{
    if(ref.current == 0){
      handleAuth();
      ref.current += 1;
    }
  }, [ref])

  return (logged == false ? <div> Authorize to see the content</div> : <div>
    <div className="flex  flex-col items-center justify-center " >
    <div className="flex">
    <button className="btn btn-primary ml-2" onClick={(e)=>{setDisplay(0)}}>
      Raw
    </button>
    <button className="btn btn-primary ml-2" onClick={(e)=>{setDisplay(1)}}>
      User Statistics
    </button>

    <button className="btn btn-success ml-2"  onClick={(e)=>{setDisplay(4)}}>
      Answered
    </button>
    
    <button className="btn btn-info ml-2" onClick={(e)=>{setDisplay(3)}}>
      Not answered
    </button>

    </div>
    <UpdateKnowledge />


    <button className="btn btn-info" onClick={(e) => {
    axios({
        url: 'https://roasbooster.us/v1/api/get-csv',
        method: 'GET',
        responseType: 'blob', // Set responseType to 'blob' for binary data
    })
    .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'output.csv');
        document.body.appendChild(link);
        link.click();
        // Cleanup
        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(url);
    })
    .catch(error => {
        alert('Error while downloading the file');
    });
}}>
    Download
</button>

    </div>

 

    <div className="w-[90%]">
    {display == 0? <RawTable questions={questions}/> : (
     display == 1 ? <UserTable questions={info} /> : (
     display == 2 ? <QuestionsTable name={'U questions'} questions={questions.filter((obj) => obj.mark == 'trash')} /> : (
     display == 3 ? <QuestionsTable name={'Unanswered questions'} questions={questions.filter((obj) => obj.mark == 'not answered')} />
     : <QuestionsTable name={'Answered questions'} questions={questions.filter((obj) => obj.mark == 'answered')} />
    )))
     }
     </div>

    </div>
    )
}

export default App;
