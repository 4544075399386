import React, { useState, useEffect } from 'react';

const UserTable = ({questions}) => {
 

  return (
    
    <div className="p-10">
      <h1 className="text-3xl font-bold mb-6" style={{fontWeight : '800'}}>User Statistics(messages)</h1>
      <table className="table w-full">
        <thead>
          <tr>
            <th>Chat ID</th>
            <th>Total</th>
            <th>User</th>
            <th>Bot</th>
          </tr>
        </thead>
        <tbody>
          {questions.map((question) => (
            <tr key={question.chat_id}>
              <td>{question.chat_id}</td>
              <td>{question.cnt}</td>
              <td className="bg-info">{question.user}</td>
              <td className="bg-error">{question.bot}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UserTable;
